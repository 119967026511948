export const trainingType = {
  Strength: "스트렝스",
  Bodybuilding: "바디빌딩",
  Crossfit: "크로스핏",
  Weightlifting: "역도",
  Bodyweight: "맨몸운동",
  Cardio: "유산소",
  Etc: "etc",
  Undefined: "모름",
};

export default trainingType;
