export const trainingType = {
  Strength: "Strength",
  Bodybuilding: "Bodybuilding",
  Crossfit: "Crossfit",
  Weightlifting: "Weightlifting",
  Bodyweight: "Bodyweight",
  Cardio: "Cardio",
  Etc: "etc",
  Undefined: "undefined",
};

export default trainingType;
